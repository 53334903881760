import gsap from 'gsap';

const progressbar = document.querySelector('.js-progressbar-inner');

const updateProgress = () => {
    const windowScroll = window.pageYOffset;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const progress = (windowScroll / height) * 100;

    gsap.to(progressbar, {
        duration: 0.05,
        width: progress + '%'
    });
};

updateProgress();

document.addEventListener('scroll', () => {
    window.requestAnimationFrame(updateProgress);
});

document.addEventListener('resize', () => {
    window.requestAnimationFrame(updateProgress);
});

window.addEventListener('load', () => {
    window.requestAnimationFrame(updateProgress);
});
