import ScrollMagic from 'scrollmagic';

const smController = new ScrollMagic.Controller();

class LineElement {
    container: HTMLDivElement;
    scene: ScrollMagic.Scene;

    constructor(el: HTMLDivElement) {
        this.container = el;
        this.scene = null;

        this.handleScroll();
        this.handleUpdate();
    }

    handleScroll(): void {
        this.scene = new ScrollMagic.Scene({
            triggerElement: this.container,
            triggerHook: 0.85,
            offset: 0,
            duration: 0,
            reverse: false
        })
            .addTo(smController)
            .on('enter', () => {
                this.container.classList.add('_is-active');
            })
            .on('leave', () => {
                this.container.classList.remove('_is-active');
            });
    }

    handleUpdate(): void {
        window.addEventListener('resize', () => {
            this.update();
        });
    }

    update(): void {
        this.scene.refresh();
    }
}

document.querySelectorAll('.js-line-element').forEach(el => {
    new LineElement(el as HTMLDivElement);
});
