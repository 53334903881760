import gsap from 'gsap';

document.querySelectorAll('.js-details-trigger').forEach(btn => {
    btn.addEventListener('click', () => {
        // btn.closest('.js-details')?.classList.toggle('_is-open');
        const container = btn.closest('.js-details');
        const content = container?.querySelector('.js-details-content');

        if (!container || !content) return;

        if (container.classList.contains('_is-open')) {
            container.classList.remove('_is-open');
            gsap.to(
                content,
                {
                    duration: 0.3,
                    height: 0
                }
            );
        } else {
            container.classList.add('_is-open');
            gsap.to(
                content,
                {
                    duration: 0.3,
                    height: 'auto'
                }
            );
        }
    });
});
