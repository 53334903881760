import { GLOBALS } from '../../js/globals';

let menuIsOpen: boolean = false;

function closeMenu() {
    GLOBALS.H.classList.remove('_has-open-menu');
    menuIsOpen = false;
}

function openMenu() {
    GLOBALS.H.classList.add('_has-open-menu');
    menuIsOpen = true;
}

document.querySelector('.js-burger')?.addEventListener('click', () => {
    if (menuIsOpen) {
        closeMenu();
    } else {
        openMenu();
    }
});

document.querySelectorAll('.js-mobile-menu a, .js-close-menu').forEach(link => {
    link.addEventListener('click', e => {
        // e.preventDefault();
        closeMenu();
    });
});
