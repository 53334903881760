/**
 * Global variables
 */
interface IGlobals {
    mobile: boolean,
    H: HTMLElement,
    readonly breakpoints: {
        xs: number,
        s: number,
        m: number,
        l: number,
        xl: number
    }
}

export const GLOBALS: IGlobals = {
    mobile: false,
    H: document.getElementsByTagName('html')[0],
    breakpoints: {
        xs: 359,
        s: 767,
        m: 999,
        l: 1299,
        xl: 2000
    }
};
